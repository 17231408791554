import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { routes, queryClient } from './routes.tsx';
import './locales';
import { FetchProvider } from './providers/FetchProvider';
import { AuthProvider } from './providers/AuthProvider';
import { getUserFromLocalStorage } from './helpers/getUserFromLocalStorage.ts';
import { Spinner } from './components/Spinner/Spinner.tsx';

Sentry.init({
  dsn: import.meta.env?.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ['localhost', /^https:\/\/app\.algoriq\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme();

const user = getUserFromLocalStorage();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense fallback="loading">
        <GoogleOAuthProvider clientId={import.meta.env?.VITE_GOOGLE_CLIENT_ID}>
          <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <FetchProvider>
                <AuthProvider parsedUser={user}>
                  <RouterProvider
                    router={createBrowserRouter(routes)}
                    fallbackElement={<Spinner type="fullScreen" />}
                  />
                </AuthProvider>
              </FetchProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </GoogleOAuthProvider>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
);
