import { useRef, useEffect } from 'react';

function useTitle(title: string, restoreOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!restoreOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [restoreOnUnmount],
  );
}

export { useTitle };
