import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
// import { AddNewHypothesisModal } from '../../components/AddNewHypothesisModal';
import type { Hypothesis } from '../../types/Hypothesis';

const HypothesisHome = () => {
  const { companyId, productId, hypothesisId } = useParams();
  const { data } = useQuery<{ data: Hypothesis }>({
    queryKey: [
      '/company',
      companyId,
      'product',
      productId,
      'hypothesis',
      hypothesisId,
    ],
  });

  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  // const [hypothesisToEdit, setHypothesisToEdit] = useState<Hypothesis | null>(
  //   null,
  // );
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.data.state === 'questionnaire') {
      return navigate(
        `/company/${data?.data.company}/product/${data?.data.product}/hypothesis/${data?.data.id}/questionnaire`,
        {
          replace: true,
        },
      );
    }
  }, [data, navigate]);

  return (
    <>
      {/* <Typography variant="h3" gutterBottom>
        {t('hypothesisHome.header')} {data?.data.name}
      </Typography> */}
      <Grid container spacing={2} alignItems="stretch">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            alignSelf: 'center',
            width: '100%',
            textAlign: 'center',
            m: 10,
          }}
        >
          {t('hypothesisHome.soon')}
        </Typography>
      </Grid>
      {/* <Box textAlign="center" m={2}>
        <Button
          onClick={() => {
            // setOpen(true);
          }}
          variant="contained"
        >
          {t('productHome.create')}
        </Button>
      </Box> */}

      {/* <AddNewHypothesisModal
        isOpen={open}
        companyId={companyId!}
        productId={productId!}
        hypothesisToEdit={hypothesisToEdit}
        handleClose={() => {
          setOpen(false);
          setHypothesisToEdit(null);
        }}
        onSuccess={(hypothesis) => {
          if (hypothesisToEdit) {
            setHypothesisToEdit(null);
          } else {
            navigate(
              `/company/${companyId}/product/${productId}/hypothesis/${hypothesis.id}`,
            );
          }
        }}
      /> */}
    </>
  );
};

export { HypothesisHome };
