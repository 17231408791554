import type { Params } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { CompanyHome } from './CompanyHome';

const companyLoader = async ({
  params,
  queryClient,
}: {
  params: Params<'companyId'>;
  queryClient: QueryClient;
}) => {
  return Promise.all([
    queryClient.fetchQuery({ queryKey: ['/company', params.companyId] }),
    queryClient.fetchQuery({
      queryKey: ['/company', params.companyId, 'product'],
    }),
  ]);
};

export { CompanyHome, companyLoader };
