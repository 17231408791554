import type { Params } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { CompanySettings } from './CompanySettings';

const companySettingsLoader = async ({
  params,
  queryClient,
}: {
  params: Params<'companyId'>;
  queryClient: QueryClient;
}) => {
  return queryClient.fetchQuery({
    queryKey: ['/company', params.companyId, 'member'],
  });
};

export { CompanySettings, companySettingsLoader };
