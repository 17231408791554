import { useState, useContext } from 'react';
import { AuthContext, FetchContext } from '../context';

import type { UserT } from '../types/User';

const AuthProvider = ({
  children,
  parsedUser,
}: {
  children: JSX.Element;
  parsedUser?: UserT;
}) => {
  const { setAuthorizationToken } = useContext(FetchContext);
  const [user, setUser] = useState(parsedUser!);
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: (newUser: UserT) => {
          setUser(newUser);
          localStorage.setItem('user', JSON.stringify(newUser || {}));
          setAuthorizationToken(newUser?.token?.token);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
