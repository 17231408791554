import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(
  props: CircularProgressProps & { currentValue: number; fullValue: number },
) {
  const { currentValue, fullValue } = props;
  const value = (currentValue / fullValue) * 100;
  if (!currentValue) {
    return '';
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', margin: '0 5px' }}>
      <CircularProgress variant="determinate" size={25} value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div">{`${Math.round(
          currentValue,
        )}`}</Typography>
      </Box>
    </Box>
  );
}

export { CircularProgressWithLabel };
