import type { Params } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { ProductQuestionnaire } from './ProductQuestionnaire';

const productQuestionnaireLoader = async ({
  params,
  queryClient,
}: {
  params: Params<'companyId' | 'productId'>;
  queryClient: QueryClient;
}) => {
  return Promise.all([
    queryClient.fetchQuery({
      queryKey: [
        '/company',
        params.companyId,
        'product',
        params.productId,
        'questionnaire',
      ],
    }),
    queryClient.fetchQuery({
      queryKey: [
        '/company',
        params.companyId,
        'product',
        params.productId,
        'questionnaire',
        'form',
      ],
    }),
  ]);
};

export { ProductQuestionnaire, productQuestionnaireLoader };
