import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

let lng = 'en';
if (typeof localStorage !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || lng;
}

i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./${language}/${namespace}.json`),
    ),
  )
  .init({
    fallbackLng: 'en',
    lng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18next.on('languageChanged', (newLang) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('i18nextLng', newLang);
  }
});
