import { setAuthorizationToken } from './fetchApi';
import type { UserT } from '../types/User';

const getUserFromLocalStorage = () => {
  let userLocal;
  if (typeof localStorage !== 'undefined') {
    const localStorageUser = localStorage.getItem('user');
    if (localStorageUser) {
      try {
        userLocal = JSON.parse(localStorageUser) as UserT;
        setAuthorizationToken(userLocal.token.token);
      } catch (e) {
        console.log('Error to parse user ', e);
      }
    }
  }
  return userLocal;
};

export { getUserFromLocalStorage };
