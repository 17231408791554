import { FetchContext } from '../context';
import { setAuthorizationToken, fetchApi } from '../helpers/fetchApi';

const FetchProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <FetchContext.Provider
      value={{
        fetchApi,
        setAuthorizationToken,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};

export { FetchProvider };
