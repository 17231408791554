import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Button, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { CompanyCard } from '../../components/CompanyCard';
import { AddNewCompanyModal } from '../../components/AddNewCompanyModal';

import type { PaginationResponce } from '../../types/PaginationResponce';
import type { Company } from '../../types/Company';

const CompaniesList = () => {
  const { t } = useTranslation();
  const { data: companiesData } = useQuery<PaginationResponce<Company>>({
    queryKey: ['/company'],
  });

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {t('companiesList.header')}
      </Typography>

      <Grid container spacing={2} alignItems="stretch">
        {companiesData?.data?.length ? (
          companiesData.data.map((company) => (
            <Grid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={company.id}
              style={{ display: 'flex' }}
            >
              <CompanyCard company={company} />
            </Grid>
          ))
        ) : (
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              alignSelf: 'center',
              width: '100%',
              textAlign: 'center',
              m: 10,
            }}
          >
            {t('companiesList.noCompanies')}
          </Typography>
        )}
      </Grid>
      <Box textAlign="center" m={2}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
        >
          {t('companiesList.create')}
        </Button>
      </Box>

      <AddNewCompanyModal
        isOpen={open}
        handleClose={() => {
          setOpen(false);
        }}
        onSuccess={(newCompany) => {
          queryClient.invalidateQueries({ queryKey: ['/company'] });
          navigate(`/company/${newCompany.id}`);
        }}
      />
    </>
  );
};

export { CompaniesList };
