import { CircularProgress } from '@mui/material';
import cls from './Spinner.module.scss';

const Spinner = ({
  type = 'simple',
}: {
  type?: 'simple' | 'fullScreen' | 'fillContainer';
}) => {
  return (
    <div className={`${cls.spinner} ${cls[type]}`}>
      <CircularProgress />
    </div>
  );
};

export { Spinner };
