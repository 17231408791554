import { useState, useEffect } from 'react';
import { Tabs, Tab, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { ConfirmModal } from '../../components/ConfirmModal';

import type {
  FieldErrors,
  UseFormRegister,
  UseFormGetValues,
  Control,
} from 'react-hook-form';

import { isAllKeysPresented } from '../../helpers/forms';
import { TabberFormInternal } from './components/TabbedFormInternal';
import type { TabsConfig } from '../../types/Tabs';
import type { FormValues } from '../../types/FormValues';

const TabbedForm = ({
  i18nPrefix,
  tabsConfig,
  lastTab,
  errors,
  register,
  control,
  getValues,
  nextStep,
  dirtyFields,
  serverValues,
  isDisabled,
}: {
  i18nPrefix: string;
  tabsConfig: TabsConfig;
  lastTab: number;
  errors: FieldErrors;
  register: UseFormRegister<FormValues>;
  control: Control<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  serverValues: FormValues;
  nextStep: () => void;
  dirtyFields: Partial<
    Readonly<{
      [x: string]: boolean;
    }>
  >;
  isDisabled?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const [currentTab, setCurrentTab] = useState(lastTab);

  useEffect(() => {
    setCurrentTab(lastTab);
  }, [lastTab]);
  const isFormHaveDirtyFields = !!dirtyFields[tabsConfig[currentTab]?.id];

  const blocker = useBlocker(isFormHaveDirtyFields);

  const isNotLastStep = currentTab !== lastTab;

  const isFormComplited = isAllKeysPresented(
    serverValues[tabsConfig[currentTab].id] as never,
    tabsConfig[currentTab].fields,
  );

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(_e, number) => setCurrentTab(number)}
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabsConfig.map((tab, index) => {
          const icon = i18n.exists(`${i18nPrefix}.form.${tab.id}.labelIcon`) ? (
            <sup style={{ fontSize: '8px' }}>
              {t(`${i18nPrefix}.form.${tab.id}.labelIcon`)}
            </sup>
          ) : (
            ''
          );
          return (
            <Tab
              label={t(`${i18nPrefix}.form.${tab.id}.label`)}
              icon={icon}
              iconPosition="end"
              key={tab.id}
              disabled={index > lastTab}
            />
          );
        })}
      </Tabs>
      <TabberFormInternal
        i18nPrefix={i18nPrefix}
        fields={tabsConfig[currentTab].fields}
        fieldPath={tabsConfig[currentTab].id}
        errors={errors}
        register={register}
        getValues={getValues}
        control={control}
        isFormsDisabled={isDisabled || isNotLastStep}
      />
      <Stack sx={{ textAlign: 'center', m: 0 }} spacing={2}>
        {!isNotLastStep && (
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormHaveDirtyFields}
          >
            {t('general.save')}
          </Button>
        )}
        <Button
          disabled={
            (isFormHaveDirtyFields && !isNotLastStep) || !isFormComplited
          }
          variant="contained"
          color="warning"
          onClick={() => {
            if (currentTab !== lastTab) {
              return setCurrentTab((c) => c + 1);
            }
            nextStep();
          }}
        >
          {t('general.nextStep')}
        </Button>
      </Stack>
      <ConfirmModal
        isOpen={blocker.state === 'blocked'}
        handleClose={() => {
          blocker?.reset && blocker.reset();
        }}
        title={t('general.confirmUnsave')}
        onConfirm={async () => {
          blocker?.proceed && blocker.proceed();
        }}
      />
    </>
  );
};

export { TabbedForm };
