import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { TextField, Button, Box, Stack } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import type { CredentialResponse } from '@react-oauth/google';

import { useTitle } from '../../hooks/useTitle';
import { useAuth } from '../../hooks/useAuth';
import { SwitcherLanguage } from '../../components/SwitcherLanguage';
import { Spinner } from '../../components/Spinner';
import { CircularProgressWithLabel } from '../../components/CircularProgressWithLabel';
import { useFetch } from '../../hooks/useFetch';

import type { UserT } from '../../types/User';

type FormValues = {
  email: string;
  otp?: string;
};

const Login = () => {
  useTitle('Login');
  const { enqueueSnackbar } = useSnackbar();

  const auth = useAuth();
  const location = useLocation();
  const [isSecondStage, setIsSecondStage] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [initialCooldown, setInitialCooldown] = useState(0);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    if (cooldown > 0) {
      const intervalId = setInterval(() => {
        setCooldown((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [cooldown]);

  const {
    handleSubmit,
    setError,
    register,
    formState: { errors },
    getValues,
  } = useForm<FormValues>();
  const { isLoading, fetchApi } = useFetch({ setError });

  if (auth.user && Object.keys(auth.user).length !== 0) {
    // AL-32
    queryClient.invalidateQueries();
    const url = location.search;
    const query = new URLSearchParams(url);
    let redirectTo = '/';
    if (query.has('redirect')) {
      redirectTo = query.get('redirect') as string;
    }
    return <Navigate to={redirectTo} />;
  }

  const onSendAgainClick = () => {
    setIsSecondStage(false);
    sendDataToServer(getValues(), true);
  };

  const sendDataToServer = async (
    values: FormValues,
    isForceStageOne = false,
  ) => {
    const isRealSecondStage = !isForceStageOne && isSecondStage;
    const val: FormValues = {
      email: values.email,
    };
    let action = '/sendOtp';
    if (isRealSecondStage) {
      val.otp = values.otp;
      action = '/verifyOTP';
    }
    const data = await fetchApi<{ user?: UserT; cooldown?: number }>(
      `/auth${action}`,
      {
        method: 'POST',
        body: val,
      },
      {
        isSkipRequestForNonAuthUser: false,
      },
    );

    if (data) {
      setIsSecondStage(true);
      if (data.data?.user) {
        auth.setUser(data.data?.user);
      } else {
        enqueueSnackbar(t('login.notifications.checkEmail'), {
          variant: 'success',
        });
      }
      if (data.data?.cooldown) {
        setCooldown(data.data.cooldown);
        setInitialCooldown(data.data.cooldown);
      }
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    return sendDataToServer(values);
  };

  const handleSuccess = async (credentialResponse: CredentialResponse) => {
    const data = await fetchApi<{ user?: UserT }>(
      `/auth/google`,
      {
        method: 'POST',
        body: { credential: credentialResponse.credential },
      },
      {
        isSkipRequestForNonAuthUser: false,
      },
    );
    if (!data) {
      return;
    }

    if (data.data?.user) {
      auth.setUser(data.data?.user);
    }
  };

  const handleError = () => {
    enqueueSnackbar(t('general.error'), {
      variant: 'error',
    });
  };

  return (
    <Box style={{ minHeight: '100vh' }}>
      {isLoading && <Spinner type="fillContainer" />}
      <Box sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}>
        <SwitcherLanguage />
      </Box>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        sx={{
          width: '300px',
          minHeight: '300px',
          height: '100vh',
          justifyContent: 'center',
          margin: '0 auto',
        }}
      >
        <img src="/algoriq.svg" height={50} />
        <TextField
          placeholder={t('login.email')}
          type="email"
          required
          helperText={String(errors.email ?? '')}
          error={Boolean(errors.email)}
          disabled={isSecondStage}
          {...register('email')}
        />
        {isSecondStage && (
          <TextField
            placeholder={t('login.otp')}
            helperText={String(errors.otp ?? '')}
            error={Boolean(errors.otp)}
            {...register('otp')}
          />
        )}
        <Button type="submit" fullWidth variant="contained">
          {t('login.login')}
        </Button>
        {!isSecondStage && (
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            width={300}
          />
        )}
        {isSecondStage && (
          <>
            <Button
              disabled={isLoading}
              onClick={() => setIsSecondStage(false)}
              fullWidth
              variant="outlined"
            >
              {t('login.changeEmail')}
            </Button>
            <Button
              disabled={!!cooldown}
              onClick={onSendAgainClick}
              fullWidth
              variant="outlined"
            >
              <CircularProgressWithLabel
                currentValue={cooldown}
                fullValue={initialCooldown}
              />
              {t('login.sendAgain')}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export { Login };
