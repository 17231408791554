import type { Params } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { HypothesisHome } from './HypothesisHome';

const hypothesisLoader = async ({
  params,
  queryClient,
}: {
  params: Params<'productId' | 'companyId' | 'hypothesisId'>;
  queryClient: QueryClient;
}) => {
  return Promise.all([
    queryClient.fetchQuery({
      queryKey: [
        '/company',
        params.companyId,
        'product',
        params.productId,
        'hypothesis',
        params.hypothesisId,
      ],
    }),
  ]);
};

export { HypothesisHome, hypothesisLoader };
