import type { Params } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { ProductHome } from './ProductHome';

const productLoader = async ({
  params,
  queryClient,
}: {
  params: Params<'productId' | 'companyId'>;
  queryClient: QueryClient;
}) => {
  return Promise.all([
    queryClient.fetchQuery({
      queryKey: ['/company', params.companyId, 'product', params.productId],
    }),
    queryClient.fetchQuery({
      queryKey: [
        '/company',
        params.companyId,
        'product',
        params.productId,
        'hypothesis',
      ],
    }),
  ]);
};

export { ProductHome, productLoader };
