import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmModal = ({
  isOpen,
  title = '',
  handleClose,
  onConfirm,
  children,
}: {
  isOpen: boolean;
  title?: string;
  children?: React.ReactNode;
  handleClose: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button onClick={handleClose}>{t('general.cancel')}</Button>
        <Button
          color="error"
          onClick={() => {
            onConfirm();
            handleClose();
          }}
        >
          {t('general.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmModal };
