import { Box, Fab, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SwitcherLanguage = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Fab size="small" aria-label="add" sx={{ m: 1 }} onClick={handleClick}>
        {t('lang')}
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => changeLanguageHandler('en')}>en</MenuItem>
        <MenuItem onClick={() => changeLanguageHandler('ru')}>ru</MenuItem>
      </Menu>
    </Box>
  );
};

export { SwitcherLanguage };
