import type { Hypothesis } from '../../types/Hypothesis';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';

const HypothesisCard = ({
  hypothesis,
  onSetingClick,
}: {
  hypothesis: Hypothesis;
  onSetingClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <CardHeader
        action={
          <>
            <IconButton
              aria-label={t('general.questionnaire')}
              component={Link}
              title={t('general.questionnaire')}
              to={`/company/${hypothesis.company}/product/${hypothesis.product}/hypothesis/${hypothesis.id}/questionnaire/`}
            >
              <ArticleIcon />
            </IconButton>
            <IconButton
              title={t('general.settings')}
              aria-label={t('general.settings')}
              onClick={onSetingClick}
            >
              <SettingsIcon />
            </IconButton>
          </>
        }
        title={hypothesis.name}
        subheader={`${t('general.stage')}: ${hypothesis.state}`}
      />
      <CardContent>
        <Typography variant="body2">{hypothesis.description}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={Link}
          to={`/company/${hypothesis.company}/product/${hypothesis.product}/hypothesis/${hypothesis.id}`}
        >
          {t('hypothesisCard.goToHypothesisPage')}
        </Button>
      </CardActions>
    </Card>
  );
};

export { HypothesisCard };
