import { Fragment, useId } from 'react';
import {
  TextField,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

import type {
  FieldErrors,
  UseFormRegister,
  UseFormGetValues,
  Control,
} from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { FieldType } from '../../../types/Tabs';
import type { FormValues } from '../../../types/FormValues';

const TabberFormInternal = ({
  i18nPrefix,
  fields,
  fieldPath,
  fieldPathForTranslate,
  errors,
  register,
  control,
  getValues,
  isFormsDisabled,
  header,
}: {
  i18nPrefix: string;
  fields: FieldType[];
  fieldPath: string;
  errors: FieldErrors;
  register: UseFormRegister<FormValues>;
  control: Control<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  isFormsDisabled: boolean;
  fieldPathForTranslate?: string;
  header?: React.ReactNode;
}) => {
  const { t, i18n } = useTranslation();
  const formId = useId();

  return (
    <Paper sx={{ mb: 2, p: 2 }}>
      {header && <>{header}</>}
      {fields.map((key) => {
        const realKey = `${fieldPath}.${key.key}`;
        const localError = (errors[fieldPath] as Record<string, unknown>) || {};
        let realFieldPathToTranslate = fieldPathForTranslate;
        if (key.type === 'array') {
          let arrayPath = fieldPath;
          let translateKey = arrayPath;
          if (key.key) {
            arrayPath = `${fieldPath}.${key.key}`;
            const pathSplitted = fieldPath.split('.');
            if (!Number.isNaN(pathSplitted[pathSplitted.length - 1])) {
              pathSplitted.pop();
              translateKey = `${pathSplitted.join('.')}.${key.key}`;
            }
            realFieldPathToTranslate = translateKey;
          }

          const formValues = getValues(arrayPath);

          const m = new Array(formValues?.length).fill('');

          return (
            <Fragment key={arrayPath}>
              {m.map((_k, arrayKey) => {
                return (
                  <TabberFormInternal
                    i18nPrefix={i18nPrefix}
                    key={`${arrayPath}_${arrayKey}`}
                    fields={key.fields as FieldType[]}
                    fieldPath={`${arrayPath}.${arrayKey}`}
                    errors={errors}
                    fieldPathForTranslate={translateKey}
                    register={register}
                    control={control}
                    getValues={getValues}
                    isFormsDisabled={isFormsDisabled}
                    header={
                      <Typography>
                        {t(
                          `${i18nPrefix}.form.${
                            realFieldPathToTranslate || arrayPath
                          }.index`,
                        )}
                        #{arrayKey + 1}
                      </Typography>
                    }
                  />
                );
              })}
            </Fragment>
          );
        }
        const baseFieldPath = `${i18nPrefix}.form.${fieldPathForTranslate || fieldPath}`;
        const isError = Boolean(localError[key.key]);
        const label = t(`${baseFieldPath}.${key.key}`);
        const placeholder = i18n.exists(
          `${baseFieldPath}.placeholders.${key.key}`,
        )
          ? t(`${baseFieldPath}.placeholders.${key.key}`)
          : '';
        const helperText = localError[key.key]
          ? (localError[key.key] as string)
          : '';

        if (key.enum) {
          return (
            <FormControl key={realKey} fullWidth margin="normal">
              <InputLabel id={formId}>{label}</InputLabel>
              <Controller
                name={realKey}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId={formId}
                    disabled={isFormsDisabled || key.isReadOnly}
                    label={label}
                    error={isError}
                    // required
                    variant="outlined"
                    defaultValue=""
                  >
                    {placeholder ? (
                      <MenuItem value="" disabled>
                        {placeholder}
                      </MenuItem>
                    ) : (
                      ''
                    )}
                    {key.enum?.map((e) => {
                      return (
                        <MenuItem value={e} key={e}>
                          {t(`${baseFieldPath}.enums.${key.key}.${e}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />

              <FormHelperText error={isError}>{helperText}</FormHelperText>
            </FormControl>
          );
        }
        return (
          <TextField
            key={realKey}
            {...register(realKey)}
            disabled={isFormsDisabled || key.isReadOnly}
            error={isError}
            variant="outlined"
            multiline
            minRows={3}
            // required
            fullWidth
            margin="normal"
            label={label}
            placeholder={placeholder}
            helperText={helperText}
          />
        );
      })}
    </Paper>
  );
};

export { TabberFormInternal };
