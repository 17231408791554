import { useForm, SubmitHandler } from 'react-hook-form';
import { useId } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';

import { useFetch } from '../../hooks/useFetch';
import { Spinner } from '../Spinner';
import { Product, ProductFormValues } from '../../types/Product';
import type { PaginationResponce } from '../../types/PaginationResponce';

const AddNewProductModal = ({
  isOpen,
  productToEdit,
  handleClose,
  onSuccess,
  companyId,
}: {
  isOpen: boolean;
  productToEdit?: Product | null;
  handleClose: () => void;
  onSuccess: (product: Product) => void;
  companyId: string;
}) => {
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ProductFormValues>({ values: productToEdit! });
  const { isLoading, fetchApi } = useFetch({ setError });
  const formsId = useId();
  const { t } = useTranslation();

  let texts = {
    title: t('addNewProductModal.title'),
    button: t('addNewProductModal.addProduct'),
  };

  if (productToEdit) {
    texts = {
      title: t('addNewProductModal.updateTitle'),
      button: t('addNewProductModal.updateTitle'),
    };
  }

  const onSubmit: SubmitHandler<ProductFormValues> = async (values) => {
    let url = `/company/${companyId}/product`;
    let method = 'POST';
    if (productToEdit) {
      url = `/company/${companyId}/product/${productToEdit.id}`;
      method = 'PATCH';
    }
    const data = await fetchApi<Product>(url, {
      method,
      body: values,
    }).catch(() => {});
    if (data?.data && data?.data.id) {
      if (productToEdit) {
        queryClient.setQueryData(
          ['/company', companyId, 'product'],
          (oldData: PaginationResponce<Product>) => {
            const dataToSet = [];
            for (const item of oldData.data) {
              if (item.id === productToEdit.id) {
                dataToSet.push(data.data);
              } else {
                dataToSet.push(item);
              }
            }
            return { ...oldData, data: dataToSet };
          },
        );
        queryClient.setQueryData(
          ['/company', companyId, 'product', productToEdit.id],
          data.data,
        );
      } else {
        queryClient.invalidateQueries({
          queryKey: ['/company', companyId, 'product'],
        });
      }
      onSuccess(data.data);
    }
  };
  return (
    <Dialog open={!!(isOpen || productToEdit)} onClose={handleClose}>
      {isLoading && <Spinner type="fillContainer" />}
      <DialogTitle>{texts.title}</DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id={formsId}>
          <TextField
            autoFocus
            margin="dense"
            label={t('addNewProductModal.productName')}
            fullWidth
            required
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            {...register('name')}
          />
          <TextField
            autoFocus
            margin="dense"
            label={t('addNewProductModal.productDescription')}
            fullWidth
            multiline
            minRows={2}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...register('description')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          {t('general.cancel')}
        </Button>
        <Button type="submit" variant="contained" form={formsId}>
          {texts.button}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AddNewProductModal };
