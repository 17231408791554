import { useForm, SubmitHandler } from 'react-hook-form';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from '@mui/material';

import { useFetch } from '../../hooks/useFetch';
import { Spinner } from '../Spinner';
import { Company, CompanyFormValues } from '../../types/Company';

const AddNewCompanyModal = ({
  isOpen,
  handleClose,
  onSuccess,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: (company: Company) => void;
}) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<CompanyFormValues>();
  const { isLoading, fetchApi } = useFetch({ setError });
  const formsId = useId();
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<CompanyFormValues> = async (values) => {
    const data = await fetchApi<Company>(`/company`, {
      method: 'POST',
      body: values,
    }).catch(() => {});
    if (data?.data && data?.data.id) {
      onSuccess(data.data);
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {isLoading && <Spinner type="fillContainer" />}
      <DialogTitle>{t('addNewCompanyModal.title')}</DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id={formsId}>
          <TextField
            autoFocus
            margin="dense"
            label={t('addNewCompanyModal.companyName')}
            fullWidth
            required
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            {...register('name')}
          />
          <TextField
            autoFocus
            margin="dense"
            label={t('addNewCompanyModal.companyDescription')}
            fullWidth
            multiline
            minRows={2}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...register('description')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          {t('general.cancel')}
        </Button>
        <Button type="submit" variant="contained" form={formsId}>
          {t('addNewCompanyModal.addCompany')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AddNewCompanyModal };
