import { useState } from 'react';
import { useParams, useRevalidator } from 'react-router-dom';
import {
  Typography,
  Stack,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import type {
  Company,
  CompanyMember,
  CompanyFormValues,
} from '../../types/Company';

import { Spinner } from '../../components/Spinner';
import { useFetch } from '../../hooks/useFetch';
import { ConfirmModal } from '../../components/ConfirmModal';
import { AddNewMemberModal } from '../../components/AddNewMemberModal';
const CompanySettings = () => {
  const params = useParams();
  const queryClient = useQueryClient();
  const { companyId } = useParams();

  const { data: company } = useQuery<{ data: Company }>({
    queryKey: ['/company', companyId],
  });

  const { data: companyMembers } = useQuery<{ data: CompanyMember[] }>({
    queryKey: ['/company', params.companyId, 'member'],
  });
  const revalidator = useRevalidator();
  const [deleteMember, setDeleteMember] = useState('');
  const [isAddNewMemberModal, setIsAddNewMemberModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isDirty },
  } = useForm<CompanyFormValues>({ values: company?.data });
  const { isLoading, fetchApi } = useFetch({ setError });

  const onSubmit: SubmitHandler<CompanyFormValues> = async (values) => {
    const data = await fetchApi<Company>(`/company/${params.companyId}`, {
      method: 'PATCH',
      body: values,
    });
    if (data) {
      queryClient.setQueryData(['/company', companyId], data);
      queryClient.invalidateQueries({ queryKey: ['/company'], exact: true });
      revalidator.revalidate();
      enqueueSnackbar(t('companySettings.notifications.updated'), {
        variant: 'success',
      });
    }
  };

  return (
    <>
      {isLoading && <Spinner type="fillContainer" />}

      <Typography variant="h3" gutterBottom>
        {t('companySettings.header')}
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        sx={{ marginBottom: 4 }}
      >
        <TextField
          label={t('companySettings.companyName')}
          margin="dense"
          fullWidth
          required
          helperText={errors.name?.message}
          error={Boolean(errors.name)}
          {...register('name')}
        />
        <TextField
          autoFocus
          label={t('companySettings.companyDescription')}
          margin="dense"
          fullWidth
          multiline
          minRows={2}
          helperText={errors.description?.message}
          error={Boolean(errors.description)}
          {...register('description')}
        />
        <Button variant="contained" type="submit" disabled={!isDirty}>
          {t('companySettings.update')}
        </Button>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h6">{t('companySettings.members')}</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('companySettings.member')}</TableCell>
                <TableCell>{t('companySettings.role')}</TableCell>
                <TableCell>{t('companySettings.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyMembers &&
                companyMembers.data.map((companyMember) => (
                  <TableRow key={companyMember.id}>
                    <TableCell>{companyMember.email}</TableCell>
                    <TableCell>{companyMember.role}</TableCell>
                    <TableCell sx={{ width: 0, whiteSpace: 'nowrap' }}>
                      <IconButton
                        onClick={() => setDeleteMember(companyMember.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          onClick={() => {
            setIsAddNewMemberModal(true);
          }}
        >
          {t('companySettings.addNew')}
        </Button>
      </Stack>
      <ConfirmModal
        isOpen={!!deleteMember}
        handleClose={() => {
          setDeleteMember('');
        }}
        title={t('companySettings.removeConfirm')}
        onConfirm={async () => {
          const data = await fetchApi<Company>(
            `/company/${params.companyId}/member/${deleteMember}`,
            {
              method: 'DELETE',
            },
            {
              isSkipFormErrorSettings: true,
            },
          );
          if (data) {
            queryClient.setQueryData(
              ['/company', params.companyId, 'member'],
              data,
            );
            enqueueSnackbar(t('companySettings.notifications.deleted'), {
              variant: 'success',
            });
          }
        }}
      />
      <AddNewMemberModal
        isOpen={isAddNewMemberModal}
        companyId={companyId!}
        handleClose={() => {
          setIsAddNewMemberModal(false);
        }}
        onSuccess={(memberLists) => {
          queryClient.setQueryData(['/company', params.companyId, 'member'], {
            data: memberLists,
          });
          enqueueSnackbar(t('companySettings.notifications.addded'), {
            variant: 'success',
          });
          setIsAddNewMemberModal(false);
        }}
      />
    </>
  );
};

export { CompanySettings };
