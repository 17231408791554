import type { Product } from '../../types/Product';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';

const ProductCard = ({
  product,
  onSetingClick,
}: {
  product: Product;
  onSetingClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <CardHeader
        action={
          <>
            <IconButton
              aria-label={t('general.questionnaire')}
              component={Link}
              title={t('general.questionnaire')}
              to={`/company/${product.company}/product/${product.id}/questionnaire`}
            >
              <ArticleIcon />
            </IconButton>
            <IconButton
              title={t('general.settings')}
              aria-label={t('general.settings')}
              onClick={onSetingClick}
            >
              <SettingsIcon />
            </IconButton>
          </>
        }
        title={product.name}
        subheader={`${t('general.stage')}: ${product.state}`}
      />
      <CardContent>
        <Typography variant="body2">{product.description}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          component={Link}
          to={`/company/${product.company}/product/${product.id}`}
        >
          {t('productCard.goToProductPage')}
        </Button>
      </CardActions>
    </Card>
  );
};

export { ProductCard };
