import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useFetch } from '../../hooks/useFetch';
import { Spinner } from '../../components/Spinner';
import { TabbedForm } from '../../components/TabbedForm';
import type { TabsConfig } from '../../types/Tabs';

import type { Company, CompanyQuestionaireResponce } from '../../types/Company';
import type { FormValues } from '../../types/FormValues';

const CompanyQuestionnaire = () => {
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data: company } = useQuery<{ data: Company }>({
    queryKey: ['/company', companyId],
  });

  const { data: questionnaire } = useQuery<{ data: FormValues }>({
    queryKey: ['/company', companyId, 'questionnaire'],
  });
  const { data: tabsConfigOrig } = useQuery<{ data: TabsConfig }>({
    queryKey: ['/company', companyId, 'questionnaire', 'form'],
  });
  const tabsConfig = tabsConfigOrig!.data;
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    setError,
    register,
    reset,
    control,
    formState: { errors, dirtyFields },
    getValues,
  } = useForm({
    values: questionnaire?.data,
  });
  const { t } = useTranslation();
  const [lastTab, setLastTab] = useState(0);
  const { isLoading, fetchApi } = useFetch({ setError });
  const navigate = useNavigate();
  const updateCompanyState = (newState: string) => {
    queryClient.setQueryData(
      ['/company', companyId],
      (oldData: { data: Company }) => {
        return {
          data: {
            ...oldData.data,
            state: newState,
          },
        };
      },
    );
  };

  useEffect(() => {
    if (questionnaire?.data.stage) {
      const index = tabsConfig.findIndex(
        (tab) => tab.id === questionnaire?.data.stage,
      );
      if (index !== lastTab) {
        setLastTab(index);
      }
    }
  }, [questionnaire, lastTab, tabsConfig]);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const data = await fetchApi<CompanyQuestionaireResponce>(
      `/company/${companyId}/questionnaire`,
      {
        method: 'PATCH',
        body: values,
      },
    );
    if (data) {
      updateCompanyState(data.data!.state);
      queryClient.setQueryData(['/company', companyId, 'questionnaire'], {
        data: data.data!.questionnaire,
      });
      enqueueSnackbar(t('companyQuestionnaire.notifications.updated'), {
        variant: 'success',
      });
      reset({}, { keepValues: true });
    }
  };

  const sendToNextStep = async () => {
    if (company!.data!.state === 'ready') {
      return navigate(`/company/${companyId}`);
    }
    const data = await fetchApi<CompanyQuestionaireResponce>(
      `/company/${companyId}/questionnaire`,
      {
        method: 'POST',
      },
    );
    if (data) {
      updateCompanyState(data.data!.state);
      if (data.data!.state === 'ready') {
        enqueueSnackbar(t('companyQuestionnaire.notifications.addProducts'), {
          variant: 'success',
        });
        return navigate(`/company/${companyId}`);
      }

      if (data.data?.questionnaire) {
        queryClient.setQueryData(['/company', companyId, 'questionnaire'], {
          data: data.data!.questionnaire,
        });
      }
    }
  };

  return (
    <Box
      sx={{ width: '100%', position: 'relative' }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant="h6">{t('companyQuestionnaire.header')}</Typography>
      <Typography variant="body2">
        {t('companyQuestionnaire.description')}
      </Typography>
      <TabbedForm
        i18nPrefix="companyQuestionnaire"
        tabsConfig={tabsConfig}
        lastTab={lastTab}
        errors={errors}
        register={register}
        control={control}
        getValues={getValues}
        serverValues={questionnaire!.data}
        dirtyFields={dirtyFields}
        nextStep={sendToNextStep}
        isDisabled={company!.data!.state === 'ready'}
      />

      {isLoading && <Spinner type="fullScreen" />}
    </Box>
  );
};

export { CompanyQuestionnaire };
