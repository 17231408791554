import { Navigate, Outlet, Params, redirect } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { Layout } from './components/Layout';
import { CompaniesList, companyListLoader } from './pages/CompaniesList';
import { CompanyHome, companyLoader } from './pages/CompanyHome';
import { ProductHome, productLoader } from './pages/ProductHome';
import {
  ProductQuestionnaire,
  productQuestionnaireLoader,
} from './pages/ProductQuestionnaire';

import { HypothesisHome, hypothesisLoader } from './pages/HypothesisHome';

import {
  HypothesisQuestionnaire,
  hypothesisQuestionnaireLoader,
} from './pages/HypothesisQuestionnaire';
import {
  CompanySettings,
  companySettingsLoader,
} from './pages/CompanySettings';
import {
  CompanyQuestionnaire,
  companyQuestionnaireLoader,
} from './pages/CompanyQuestionnaire';
import { Login } from './pages/Login';
import { Page404 } from './pages/Page404';

import { authorizationToken, fetchApi } from './helpers/fetchApi';

import type { Company } from './types/Company';
import { Product } from './types/Product';
import { Hypothesis } from './types/Hypothesis';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      queryFn: async ({ queryKey, signal }) => {
        const path = [];
        for (const val of queryKey) {
          if (typeof val === 'string') {
            path.push(val);
          }
        }
        return fetchApi(path.join('/'), { signal });
      },
    },
  },
});

const routes = [
  {
    path: '/',
    element: <Navigate to="/company" />,
  },
  {
    path: '/company',
    element: <Layout isRequiredAuth />,
    loader: () => {
      if (!authorizationToken) {
        return redirect(`/login?redirect=${location.pathname}`, 302);
      }
      return null;
    },
    handle: {
      crumb: () => ({
        name: 'breadcrumbs.home',
      }),
    },
    children: [
      {
        index: true,
        element: <CompaniesList />,
        loader: () => companyListLoader(queryClient),
      },
      {
        path: ':companyId',
        id: 'company',
        element: <Outlet />,
        loader: ({ params }: { params: Params<'companyId'> }) =>
          companyLoader({ params, queryClient }),
        handle: {
          crumb: (data: [{ data: Company }]) => ({
            link: `/company/${data[0].data.id}`,
            name: data[0].data.name,
          }),
        },
        children: [
          {
            index: true,
            element: <CompanyHome />,
          },
          {
            path: 'questionnaire',
            element: <CompanyQuestionnaire />,
            loader: ({ params }: { params: Params<'companyId'> }) =>
              companyQuestionnaireLoader({ params, queryClient }),
            handle: {
              crumb: () => ({
                name: 'breadcrumbs.questionnaire',
              }),
            },
          },
          {
            path: 'settings',
            element: <CompanySettings />,
            loader: ({ params }: { params: Params<'companyId'> }) =>
              companySettingsLoader({ params, queryClient }),
            handle: {
              crumb: () => ({
                name: 'breadcrumbs.settings',
              }),
            },
          },
          {
            path: 'product',
            element: <Outlet />,
            // handle: {
            //   crumb: () => ({
            //     name: 'breadcrumbs.product',
            //   }),
            // },
            children: [
              {
                index: true, // todo can be removed
                element: <CompanyHome />,
              },
              {
                path: ':productId',
                element: <Outlet />,
                loader: ({
                  params,
                }: {
                  params: Params<'companyId' | 'productId'>;
                }) => productLoader({ params, queryClient }),
                handle: {
                  crumb: (data: [{ data: Product }]) => ({
                    link: `/company/${data[0].data.company}/product/${data[0].data.id}`,
                    name: data[0].data.name,
                  }),
                },

                children: [
                  {
                    index: true, // todo can be removed
                    element: <ProductHome />,
                  },
                  {
                    path: 'questionnaire',
                    element: <ProductQuestionnaire />,
                    loader: ({
                      params,
                    }: {
                      params: Params<'companyId' | 'productId'>;
                    }) => productQuestionnaireLoader({ params, queryClient }),
                    handle: {
                      crumb: () => ({
                        name: 'breadcrumbs.questionnaire',
                      }),
                    },
                  },
                  {
                    path: 'hypothesis',
                    element: <Outlet />,
                    // handle: {
                    //   crumb: () => ({
                    //     name: 'breadcrumbs.hypothesis',
                    //   }),
                    // },
                    children: [
                      {
                        index: true, // todo can be removed
                        element: <ProductHome />,
                      },
                      {
                        path: ':hypothesisId',
                        element: <Outlet />,
                        loader: ({
                          params,
                        }: {
                          params: Params<
                            'companyId' | 'productId' | 'hypothesisId'
                          >;
                        }) => hypothesisLoader({ params, queryClient }),
                        handle: {
                          crumb: (data: [{ data: Hypothesis }]) => ({
                            link: `/company/${data[0].data.company}/product/${data[0].data.product}/hypothesis/${data[0].data.id}`,
                            name: data[0].data.name,
                          }),
                        },
                        children: [
                          {
                            index: true, // todo can be removed
                            element: <HypothesisHome />,
                          },
                          {
                            path: 'questionnaire',
                            element: <HypothesisQuestionnaire />,
                            loader: ({
                              params,
                            }: {
                              params: Params<
                                'companyId' | 'productId' | 'hypothesisId'
                              >;
                            }) =>
                              hypothesisQuestionnaireLoader({
                                params,
                                queryClient,
                              }),
                            handle: {
                              crumb: () => ({
                                name: 'breadcrumbs.questionnaire',
                              }),
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  { path: '*', element: <Page404 /> },
];

export { routes, queryClient };
