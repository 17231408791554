import type { Params } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { CompanyQuestionnaire } from './CompanyQuestionnaire';

const companyQuestionnaireLoader = async ({
  params,
  queryClient,
}: {
  params: Params<'companyId'>;
  queryClient: QueryClient;
}) => {
  return Promise.all([
    queryClient.fetchQuery({
      queryKey: ['/company', params.companyId, 'questionnaire'],
    }),
    queryClient.fetchQuery({
      queryKey: ['/company', params.companyId, 'questionnaire', 'form'],
    }),
  ]);
};

export { CompanyQuestionnaire, companyQuestionnaireLoader };
