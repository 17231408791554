import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { FetchContext } from '../context';

import type { UseFormSetError } from 'react-hook-form';

function useFetch({
  setError = () => {},
  showErrorNotification = true,
  showSuccesNotification = true,
}: {
  setError?: UseFormSetError<object>;
  showErrorNotification?: boolean;
  showSuccesNotification?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { fetchApi } = useContext(FetchContext);

  return {
    isLoading,
    fetchApi: async <T,>(
      url: string,
      params: Omit<RequestInit, 'body'> & {
        body?: BodyInit | null | FormData | object;
      },
      additionalParams?: {
        isSkipRequestForNonAuthUser?: boolean;
        isSkipFormErrorSettings?: boolean;
      },
    ) => {
      setIsLoading(true);
      const data = await fetchApi<T>(url, params, additionalParams)
        .catch((e) => {
          if (e.message && showErrorNotification) {
            enqueueSnackbar(e.message, {
              variant: 'error',
            });
          }
          if (e.errors && !additionalParams?.isSkipFormErrorSettings) {
            Object.entries(e.errors).forEach(([key, value]) => {
              value &&
                setError(key as 'root', {
                  type: 'custom',
                  message: (value as Array<string>).join(','),
                });
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
      if (data?.message && showSuccesNotification) {
        enqueueSnackbar(data.message);
      }
      return data;
    },
  };
}

export { useFetch };
