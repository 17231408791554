import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useFetch } from '../../hooks/useFetch';
import { Spinner } from '../../components/Spinner';
import { TabbedForm } from '../../components/TabbedForm';
import type { TabsConfig } from '../../types/Tabs';

import type { Product, ProductQuestionaireResponce } from '../../types/Product';
import type { FormValues } from '../../types/FormValues';

const ProductQuestionnaire = () => {
  const { companyId, productId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data: product } = useQuery<{ data: Product }>({
    queryKey: ['/company', companyId, 'product', productId],
  });

  const { data: questionnaire } = useQuery<{ data: FormValues }>({
    queryKey: ['/company', companyId, 'product', productId, 'questionnaire'],
  });
  const { data: tabsConfigOrig } = useQuery<{ data: TabsConfig }>({
    queryKey: [
      '/company',
      companyId,
      'product',
      productId,
      'questionnaire',
      'form',
    ],
  });
  const tabsConfig = tabsConfigOrig!.data;
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    setError,
    register,
    control,
    reset,
    formState: { errors, dirtyFields },
    getValues,
  } = useForm({
    values: questionnaire?.data,
  });
  const { t } = useTranslation();
  const [lastTab, setLastTab] = useState(0);
  const { isLoading, fetchApi } = useFetch({ setError });
  const navigate = useNavigate();
  const updateProductState = (newState: string) => {
    queryClient.setQueryData(
      ['/company', companyId, 'product', productId],
      (oldData: { data: Product }) => {
        return {
          data: {
            ...oldData.data,
            state: newState,
          },
        };
      },
    );
  };

  useEffect(() => {
    if (questionnaire?.data.stage) {
      const index = tabsConfig.findIndex(
        (tab) => tab.id === questionnaire?.data.stage,
      );
      if (index !== lastTab) {
        setLastTab(index);
      }
    }
  }, [questionnaire, lastTab, tabsConfig]);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const data = await fetchApi<ProductQuestionaireResponce>(
      `/company/${companyId}/product/${productId}/questionnaire`,
      {
        method: 'PATCH',
        body: values,
      },
    );
    if (data) {
      updateProductState(data.data!.state);
      queryClient.setQueryData(
        ['/company', companyId, 'product', productId, 'questionnaire'],
        {
          data: data.data!.questionnaire,
        },
      );
      enqueueSnackbar(t('productQuestionnaire.notifications.updated'), {
        variant: 'success',
      });
      reset({}, { keepValues: true });
    }
  };

  const sendToNextStep = async () => {
    if (product!.data!.state === 'ready') {
      return navigate(`/company/${companyId}/product/${productId}`);
    }
    const data = await fetchApi<ProductQuestionaireResponce>(
      `/company/${companyId}/product/${productId}/questionnaire`,
      {
        method: 'POST',
      },
    );
    if (data) {
      updateProductState(data.data!.state);
      if (data.data!.state === 'ready') {
        enqueueSnackbar(t('productQuestionnaire.notifications.addHypothesis'), {
          variant: 'success',
        });
        return navigate(`/company/${companyId}/product/${productId}`);
      }

      if (data.data?.questionnaire) {
        queryClient.setQueryData(
          ['/company', companyId, 'product', productId, 'questionnaire'],
          {
            data: data.data!.questionnaire,
          },
        );
      }
    }
  };

  return (
    <Box
      sx={{ width: '100%', position: 'relative' }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography variant="h6">{t('productQuestionnaire.header')}</Typography>
      <Typography variant="body2">
        {t('productQuestionnaire.description')}
      </Typography>
      <TabbedForm
        i18nPrefix="productQuestionnaire"
        tabsConfig={tabsConfig}
        lastTab={lastTab}
        errors={errors}
        register={register}
        control={control}
        getValues={getValues}
        serverValues={questionnaire!.data}
        dirtyFields={dirtyFields}
        nextStep={sendToNextStep}
        isDisabled={product!.data!.state === 'ready'}
      />

      {isLoading && <Spinner type="fullScreen" />}
    </Box>
  );
};

export { ProductQuestionnaire };
