import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AddNewHypothesisModal } from '../../components/AddNewHypothesisModal';
import { HypothesisCard } from '../../components/HypothesisCard';
import type { Product } from '../../types/Product';
import type { Hypothesis } from '../../types/Hypothesis';
import type { PaginationResponce } from '../../types/PaginationResponce';

const ProductHome = () => {
  const { companyId, productId } = useParams();
  const { data } = useQuery<{ data: Product }>({
    queryKey: ['/company', companyId, 'product', productId],
  });
  const { data: hypothesisData } = useQuery<PaginationResponce<Hypothesis>>({
    queryKey: ['/company', companyId, 'product', productId, 'hypothesis'],
  });

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [hypothesisToEdit, setHypothesisToEdit] = useState<Hypothesis | null>(
    null,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.data.state === 'questionnaire') {
      return navigate(
        `/company/${data?.data.company}/product/${data?.data.id}/questionnaire`,
        {
          replace: true,
        },
      );
    }
  }, [data, navigate]);

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {t('productHome.header')} {data?.data.name}
      </Typography>
      <Grid container spacing={2} alignItems="stretch">
        {hypothesisData?.data?.length ? (
          hypothesisData.data.map((hypothesis) => (
            <Grid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={hypothesis.id}
              style={{ display: 'flex' }}
            >
              <HypothesisCard
                hypothesis={hypothesis}
                onSetingClick={() => setHypothesisToEdit(hypothesis)}
              />
            </Grid>
          ))
        ) : (
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              alignSelf: 'center',
              width: '100%',
              textAlign: 'center',
              m: 10,
            }}
          >
            {t('productHome.noHypothesis')}
          </Typography>
        )}
      </Grid>
      <Box textAlign="center" m={2}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
        >
          {t('productHome.create')}
        </Button>
      </Box>

      <AddNewHypothesisModal
        isOpen={open}
        companyId={companyId!}
        productId={productId!}
        hypothesisToEdit={hypothesisToEdit}
        handleClose={() => {
          setOpen(false);
          setHypothesisToEdit(null);
        }}
        onSuccess={(hypothesis) => {
          if (hypothesisToEdit) {
            setHypothesisToEdit(null);
          } else {
            navigate(
              `/company/${companyId}/product/${productId}/hypothesis/${hypothesis.id}`,
            );
          }
        }}
      />
    </>
  );
};

export { ProductHome };
