import { FieldType } from '../types/Tabs';

function isAllKeysPresented(obj: never, keys: FieldType[]): boolean {
  for (const key of keys) {
    if (key.type === 'array') {
      if (key.key) {
        return (obj[key.key] as Array<never>).every((a) =>
          isAllKeysPresented(a, key.fields as FieldType[]),
        );
      }
      return (obj as Array<never>).every((a) =>
        isAllKeysPresented(a, key.fields as FieldType[]),
      );
    }

    if (!(key.key in obj) || !obj[key.key]) {
      return false;
    }
  }
  return true;
}

export { isAllKeysPresented };
