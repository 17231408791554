import { useForm, SubmitHandler } from 'react-hook-form';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useFetch } from '../../hooks/useFetch';
import { Spinner } from '../Spinner';
import { CompanyMember, CompanyMemberFormValues } from '../../types/Company';
import { UserRoleT } from '../../types/User';

const AddNewMemberModal = ({
  isOpen,
  handleClose,
  onSuccess,
  companyId,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: (members: CompanyMember[]) => void;
  companyId: string;
}) => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<CompanyMemberFormValues>();
  const { isLoading, fetchApi } = useFetch({ setError });
  const formsId = useId();
  const selectId = useId();
  const { t } = useTranslation();

  const { data: userRoles } = useQuery<{
    data: UserRoleT[];
  }>({
    queryKey: ['/company', companyId, 'member', 'roles'],
  });

  const onSubmit: SubmitHandler<CompanyMemberFormValues> = async (values) => {
    const data = await fetchApi<CompanyMember[]>(
      `/company/${companyId}/member`,
      {
        method: 'POST',
        body: values,
      },
    ).catch(() => {});
    if (data?.data) {
      onSuccess(data.data);
    }
  };
  if (!userRoles) {
    return null;
  }
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {isLoading && <Spinner type="fillContainer" />}
      <DialogTitle>{t('addNewMemberModal.title')}</DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id={formsId}>
          <TextField
            autoFocus
            margin="dense"
            label={t('addNewMemberModal.memberEmail')}
            fullWidth
            type="email"
            required
            helperText={errors.email?.message}
            error={Boolean(errors.email)}
            {...register('email')}
          />
          <FormControl
            fullWidth
            error={Boolean(errors.role)}
            sx={{ marginTop: 2, minWidth: 150 }}
          >
            <InputLabel id={selectId}>
              {t('addNewMemberModal.userRole')}
            </InputLabel>
            <Select
              id={selectId}
              label={t('addNewMemberModal.userRole')}
              {...register('role')}
              required
            >
              {userRoles?.data?.map((role) => {
                return (
                  <MenuItem value={role.value} key={role.value}>
                    {role.name}({role.description})
                  </MenuItem>
                );
              })}
            </Select>
            {errors.role?.message && (
              <FormHelperText>{errors.role?.message}</FormHelperText>
            )}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="button">
          {t('general.cancel')}
        </Button>
        <Button type="submit" variant="contained" form={formsId}>
          {t('addNewMemberModal.addMember')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AddNewMemberModal };
