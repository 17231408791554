import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Typography, Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useTranslation } from 'react-i18next';
import { AddNewProductModal } from '../../components/AddNewProductModal';
import { ProductCard } from '../../components/ProductCard';
import type { Company } from '../../types/Company';
import type { Product } from '../../types/Product';
import type { PaginationResponce } from '../../types/PaginationResponce';

const CompanyHome = () => {
  const { companyId } = useParams();
  const { data } = useQuery<{ data: Company }>({
    queryKey: ['/company', companyId],
  });
  const { data: productData } = useQuery<PaginationResponce<Product>>({
    queryKey: ['/company', companyId, 'product'],
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState<Product | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.data.state === 'questionnaire') {
      return navigate(`/company/${data?.data.id}/questionnaire`, {
        replace: true,
      });
    }
  }, [data, navigate]);
  return (
    <>
      <Typography variant="h3" gutterBottom>
        {t('companyHome.header')} {data?.data.name}
      </Typography>
      <Grid container spacing={2} alignItems="stretch">
        {productData?.data?.length ? (
          productData.data.map((product) => (
            <Grid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={product.id}
              style={{ display: 'flex' }}
            >
              <ProductCard
                product={product}
                onSetingClick={() => setProductToEdit(product)}
              />
            </Grid>
          ))
        ) : (
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              alignSelf: 'center',
              width: '100%',
              textAlign: 'center',
              m: 10,
            }}
          >
            {t('companyHome.noProducts')}
          </Typography>
        )}
      </Grid>
      <Box textAlign="center" m={2}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
        >
          {t('companyHome.create')}
        </Button>
      </Box>

      <AddNewProductModal
        isOpen={open}
        companyId={companyId!}
        productToEdit={productToEdit}
        handleClose={() => {
          setOpen(false);
          setProductToEdit(null);
        }}
        onSuccess={(newProduct) => {
          if (productToEdit) {
            setProductToEdit(null);
          } else {
            navigate(`/company/${companyId}/product/${newProduct.id}`);
          }
        }}
      />
    </>
  );
};

export { CompanyHome };
