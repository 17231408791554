import i18n from 'i18next';

const baseURL = import.meta.env?.VITE_SERVER_URL;
let authorizationToken: string;

const fetchApi = async <T = Record<string, unknown>>(
  url: string | unknown,
  params: Omit<RequestInit, 'body'> & {
    body?: BodyInit | null | FormData | object;
  } = {},
  additionalParams: {
    isSkipRequestForNonAuthUser?: boolean;
  } = { isSkipRequestForNonAuthUser: true },
) => {
  if (additionalParams.isSkipRequestForNonAuthUser && !authorizationToken) {
    console.warn(
      'isSkipRequestForNonAuthUser is true and you want to do an a fetch. Skipping',
    );
    return null;
  }
  const clonedParams = { ...params };
  const headers = new Headers(clonedParams.headers);
  delete clonedParams.headers;
  headers.append('X-Lang', i18n.language);
  if (authorizationToken) {
    headers.append('Authorization', authorizationToken);
  }
  let { body } = clonedParams;
  delete clonedParams.body;

  if (body) {
    if (body instanceof FormData) {
      headers.delete('Content-Type'); // fetch will take care of it
    } else if (typeof body === 'object') {
      headers.append('Content-Type', 'application/json');
      body = JSON.stringify(body);
    }
  }
  if (body) {
    clonedParams.body = body;
  }

  const responce = await fetch(new URL(url as string, baseURL).href, {
    headers,
    ...clonedParams,
  } as RequestInit);

  type ServerResponce = {
    message?: string;
    data?: T; // | Record<string, unknown>[];
    errors?: Record<string, string[]>;
  };

  let data: ServerResponce = {};
  try {
    data = (await responce.json()) as ServerResponce;
  } catch (e) {
    /* empty */
  }

  if (responce.status >= 400) {
    return Promise.reject(data as ServerResponce);
  }
  return data as ServerResponce;
};

const setAuthorizationToken = (token: string) => {
  authorizationToken = token;
};

export { fetchApi, setAuthorizationToken, authorizationToken };
